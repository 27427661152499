// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/modules/notion/use-cases/status.ts"
);
import.meta.hot.lastModified = "1718830289043.9336";
}
// REMIX HMR END

import { Notion } from '../data-access/notion.server';

export async function fetchStatusEntryUseCase(
	data: { id: string },
	context: { notionToken: string; databaseId: string }
) {
	const notion = new Notion(context.notionToken);
	return await notion.fetchScreenshareEntry({ databaseId: context.databaseId, code: data.id });
}
