// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/index.tsx");
  import.meta.hot.lastModified = "1719166068637.1033";
}
// REMIX HMR END

import { Container, Text, PinInput, Flex, Loader, Alert } from '@mantine/core';
import { redirect } from '@remix-run/cloudflare';
import { fetchStatusEntryUseCase } from '#app/modules/notion/use-cases/status';
import { metaTags } from '#app/root';
import { useEffect, useState } from 'react';
import { useFetcher } from '@remix-run/react';
import * as jose from 'jose';
export const meta = () => {
  return metaTags({
    title: 'screenwork Meeting'
  });
};
export const headers = () => ({
  'Cache-Control': 'max-age=30',
  'X-Robots-Tag': 'noindex,nofollow'
});
async function createRedirectUrl(context, lobbyUrl) {
  const secret = new TextEncoder().encode(context.env.JWT_TOKEN);
  const token = await new jose.SignJWT({
    role: ['user']
  }).setProtectedHeader({
    alg: 'HS256'
  }).setIssuedAt().setIssuer('urn:screenwork:issuer').setAudience('urn:screenwork:audience').setExpirationTime('6h').sign(secret);
  const url = new URL(lobbyUrl);
  return url.protocol + '//' + url.hostname + '/' + '?access_token=' + encodeURIComponent(token) + '#' + encodeURIComponent(url.hash.substring(1));
}
export const action = async ({
  request,
  context
}) => {
  const formData = await request.formData();
  const accessCode = formData.get('code');
  if (!accessCode || Number.isNaN(Number(accessCode))) {
    return false;
  }
  const entries = await fetchStatusEntryUseCase({
    id: accessCode
  }, {
    notionToken: context.env.NOTION_TOKEN,
    databaseId: context.env.NOTION_SCREENSHARE_DATABASE_ID
  });
  if (entries.length > 0) {
    let redirectUrl = await createRedirectUrl(context, entries[0].url);
    return redirect(redirectUrl);
  }
  return false;
};
export default function Status() {
  _s();
  const [inputCode, setInputCode] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const fetcher = useFetcher();
  const submit = () => {
    setSubmitted(true);
    fetcher.submit({
      code: inputCode
    }, {
      method: 'post'
    });
  };
  useEffect(() => {
    if (fetcher.data !== undefined) {
      if (fetcher.data === false) {
        setSubmitted(false);
      }
    }
  }, [fetcher.data]);
  return <Container size="md">
			<Text mt="xs">Geben Sie dazu Ihre 4 Stellige PIN ein, die Sie von uns erhalten haben.</Text>

			{fetcher.data === false && <Alert color="red" mt="md">
					Sie haben einen falschen Zugangscode eingegeben!
				</Alert>}
			<Flex justify="center" mt="xl">
				<form method="post">
					{submitted && fetcher.data !== false ? <>
							<Loader size={30} />
						</> : <>
							<PinInput size="xl" value={inputCode} onChange={setInputCode} onComplete={submit} type={/^[0-9]*$/} inputType="tel" inputMode="numeric" />
						</>}
				</form>
			</Flex>
		</Container>;
}
_s(Status, "VWxXF+ZM18B9z6dERJEFsoqrs/Y=", false, function () {
  return [useFetcher];
});
_c = Status;
var _c;
$RefreshReg$(_c, "Status");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;